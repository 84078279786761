import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';

import { THEME } from '../constants/colors';

import { CollapsibleText } from './common/collapsible-text';

export class Description extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data } = this.props;

    return (
      <div style={styles.descCt}>
        <CollapsibleText text={data.appDescription} />
      </div>
    );
  }

}

const styles = {
  descCt: {
    marginTop: '9vw',
    paddingTop: '4.5vw',
    borderTop: `solid 0.5px ${THEME.BORDER}`,
  },
};
