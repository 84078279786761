import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';

import { THEME } from '../constants/colors';

import STAR_XS_ICON from '../assets/icons/star-xs.png';
import STAR_MD_EMPTY from '../assets/icons/star-md-empty.png';
import STAR_MD_FILLED from '../assets/icons/star-md-filled.png';

export class Reviews extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  stars = (new Array(5)).fill(null).map((item, index) => (new Array(5 - index)).fill(null));

  render() {
    const { data } = this.props;

    return (
      <div style={styles.ct}>
        <div style={styles.title}>{'Ratings & Reviews'}</div>
        <div style={styles.ratingCt}>
          <div style={styles.rating}>
            <div style={styles.ratingNumber}>{data.rating}</div>
            <div style={styles.ratingTop}>{'out of 5'}</div>
          </div>
          <div style={styles.ratingsRatioCt}>
            <div style={styles.ratingRatioRowCt}>
              {data.ratingsRatio.map((item, index) => (
                <div style={styles.ratingRatioRow} key={`${index}`}>
                  {this.stars[index].map((item, index) => (<img src={STAR_XS_ICON} alt={`star ${index}`} style={styles.ratingRatioImg} key={`${index}`} />))}
                  <div style={styles.ratingRatioWrapper}>
                    <div style={{ ...styles.ratingRatio, width: `${100 * item}%`, }} />
                  </div>
                </div>
              ))}
            </div>
            <div style={styles.ratingTotal}>{`${data.ratingsCount} Ratings`}</div>
          </div>
        </div>

        <div style={styles.sliderCt}>
          {data.reviews.map(({ title, rating, text }, index) => (
            <div
              key={`${index}`}
              style={{
                ...styles.reviewCt,
                ...(index === 0 ? styles.reviewCtFirst : null),
                ...(index === data.reviews.length - 1 ? styles.reviewCtLast : null),
              }}
            >
              <div style={styles.review}>
                <div style={styles.reviewTitle}>{title}</div>
                <div style={styles.reviewStarsCt}>
                  {this.stars.map((item, index) => (
                    <img
                      src={index < rating ? STAR_MD_FILLED : STAR_MD_EMPTY}
                      alt={`star ${index}`}
                      style={styles.reviewStar}
                      key={`${index}`}
                    />
                  ))}
                </div>
                <div style={styles.reviewText}>{text}</div>
              </div>
            </div>
          ))
          }
        </div>

      </div>
    );
  }

}

const styles = {
  ct: {
    marginTop: '9vw',
    paddingTop: '4.5vw',
    borderTop: `solid 0.5px ${THEME.BORDER}`,
  },
  title: {
    fontSize: '5.85vw',
    fontWeight: 'bold',
    lineHeight: '4vw',
    color: THEME.TEXT,
  },
  ratingCt: {
    marginTop: '5vw',
    display: 'flex',
    flexDirection: 'row',
  },
  rating: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ratingNumber: {
    fontWeight: 'bold',
    fontSize: '15.5vw',
    lineHeight: '11.5vw',
    letterSpacing: '-0.1vw',
    color: THEME.REVIEW_RATING,
  },
  ratingTop: {
    marginTop: '1.5vw',
    fontWeight: 'bold',
    letterSpacing: '-0.1vw',
    color: THEME.REVIEW_RATING_SECONDARY,
  },
  ratingsRatioCt: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingTop: '0.3vw',
  },
  ratingRatioRowCt: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  ratingRatioRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '0.4vw',
  },
  ratingRatioImg: {
    width: '1.9vw',
    height: '1.9vw',
    marginLeft: '0.45vw',
  },
  ratingRatioWrapper: {
    width: '44vw',
    height: '0.8vw',
    borderRadius: '0.4vw',
    background: THEME.RATING_BAR_BG,
    overflow: 'hidden',
    marginTop: '0.2vw',
    marginLeft: '2.4vw',
  },
  ratingRatio: {
    height: '100%',
    background: THEME.RATING_BAR,
  },
  ratingTotal: {
    fontSize: '4vw',
    color: THEME.REVIEW_RATING_SECONDARY,
  },

  sliderCt: {
    margin: '5.5vw -5.5vw',
    width: '100vw',
    height: '59vw',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
  },
  reviewCtFirst: {
    paddingLeft: '5.5vw',
    flex: '0 0 96vw',
  },
  reviewCtLast: {
    paddingRight: '5.5vw',
    flex: '0 0 96vw',
  },
  reviewCt: {
    flex: '0 0 92vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    scrollSnapAlign: 'center',
    padding: '0px 1.5vw',
  },
  review: {
    width: '100%',
    height: '59vw',
    display: 'flex',
    flexDirection: 'column',
    background: THEME.REVIEW_BG,
    borderRadius: '3vw',
    padding: '4vw 5vw',
  },
  reviewTitle: {
    fontWeight: 500,
    letterSpacing: '-0.1vw',
    fontSize: '4.3vw',
    color: THEME.TEXT,
  },
  reviewStarsCt: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5vw 0',
  },
  reviewStar: {
    width: '3.5vw',
    height: '3.5vw',
    marginRight: '0.45vw',
  },
  reviewText: {
    marginTop: '2.5vw',
    letterSpacing: '-0.1vw',
    lineHeight: '5.3vw',
    color: THEME.TEXT,
    overflowY: 'scroll'
  },
};
