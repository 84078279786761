import queryString from 'query-string';


const BASE_URL = 'https://splitpanel.wowmaking.net/api/v1'

const api = {

  appId: null,
  experimentId: null,
  variantId: null,

  pageData: null,

  trackId: null,
  _trackIdPromise: null,

  init() {
    const { id, e, } = queryString.parse(window.location.search);
  
    this.appId = id;
    this.experimentId = e;

    return this._getPageData();
  },

  trackInstall() {
    if (this._trackIdPromise === null) {
      const formData = new FormData();
      const id = this.appId || this.pageData?.id;
      if (id) {
        formData.append('applicationId', id);
      }
      this.experimentId && formData.append('experimentId', this.experimentId);
      this.variantId && formData.append('variantId', this.variantId);

      this._trackIdPromise = this._request(`application-install`, {
        method: 'POST',
        body: formData,
      })
        .then(data => {
          this.trackId = data.id;
          console.log(this.trackId);
        });
    }
  },

  trackEmail(email) {
    if (this._trackIdPromise !== null) {
      const formData = new FormData();
      formData.append('email', email);

      this._trackIdPromise
        .then(() => {
          this._request(`application-history/${this.trackId}/email`, {
            method: 'POST',
            body: formData,
          });
        });
    }
  },

  _getPageData() {
    if (this.pageData != null) return Promise.resolve(this.pageData);

    return this._request(`applications/${this.appId || ''}`, { query: { e: this.experimentId, d: window.location.host, }, })
      .then((responceData) => {
        this.pageData = {
          id: responceData.id,
          experimentId: responceData.selectedExperimentId,
          variantId: responceData.selectedVariantId,
          appName: responceData.appName,
          appSubName: responceData.appSubName,
          publisherName: responceData.publisherName,
          iconImage: responceData.iconImage,
          bannerImage: responceData.bannerImage,
          rating: responceData.rating,
          ratingsCount: responceData.ratingsCount,
          ageRestrictions: responceData.ageRestrictions,
          version: responceData.version,
          whatsNewDesc: responceData.whatsNewDesc,
          previews: responceData.relations.previews,
          appDescription: responceData.appDescription,
          ratingsRatio: responceData.relations.ratings.sort((a, b) => b.stars - a.stars).map(i => i.ratio),
          reviews: responceData.relations.reviews,
          size: responceData.size,
          category: responceData.category,
          copyright: responceData.copyright,
          isPortraitPreviews: responceData.isPortraitPreviews,
          storeLink: responceData.storeLink,
          mmpLink: responceData.mmpLink,
        }

        this.experimentId = responceData.selectedExperimentId;
        this.variantId = responceData.selectedVariantId;

        this._appendHtml(responceData.html);
        this._appendJs(responceData.javaScript);

        return this.pageData;
      });
  },

  _appendHtml(html) {
    if (html) {
      document.body.insertAdjacentHTML('beforeend', html);
    }
  },

  _appendJs(js) {
    if (js) {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.textContent = js;

      document.body.append(script);
    }
  },

  _request(url, options) {
    const p = queryString.stringifyUrl({ url: `${BASE_URL}/${url}`, query: options.query });
    return fetch(p, options)
      .then((response) => response.json());
  }
};

export default api;
