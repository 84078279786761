import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Attribution from '../../modules/attribution';
import Api from '../../modules/api';
import Analytics from '../../modules/analytics';

import { THEME, isDarkTheme, } from '../../constants/colors';

import SHARE_ICON from '../../assets/icons/share.png';
import STAR_ICON_WHITE from '../../assets/icons/star-white.png';
import STAR_ICON_BLACK from '../../assets/icons/star-black.png';

import './index.css';

export class Header extends PureComponent {
  componentDidMount() {
    document.addEventListener("scroll", this.isInViewport);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.isInViewport);
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  state = {
    isVisibleHeader: false,
  };

  stars = (new Array(5)).fill(null);

  render() {
    const { data } = this.props;
    const { isVisibleHeader } = this.state;
    const fixedHeaderClassName = isVisibleHeader ? 'fixed-header-in' : 'fixed-header-out';

    return (
      <>
        <div style={styles.fixedHeader} className={`fixed-header ${fixedHeaderClassName}`}>
          <img src={data.iconImage} alt={'logo'} style={styles.logo} />
          <button style={styles.fixedGetBtn} id={'get-btn'} onClick={this.handleGetClick}>Get</button>
        </div>
        <div style={styles.ct}>
          {/* <div style={styles.imgCt}> */}
          <img src={data.iconImage} alt={'icon'} style={styles.img} />
          {/* </div> */}
          <div style={styles.infoCt}>
            <div style={styles.info}>
              <div style={styles.appName}>{data.appName}</div>
              <div style={styles.appSubName}>{data.appSubName}</div>
            </div>
            <div style={styles.controls}>
              <button style={styles.getBtn} id={'get-btn'} onClick={this.handleGetClick} ref={this.setRef}>Get</button>
              <button style={styles.shareBtn} id={'share-btn'}>
                <img src={SHARE_ICON} alt={'share'} style={styles.shareBtnIcon} />
              </button>
            </div>
          </div>
        </div>
        <div style={styles.bottomCt}>
          <div>
            <div style={styles.startRatingCt}>
              <div style={styles.startRating}>{data.rating}</div>
              {this.stars.map((item, index) => (
                <div style={styles.startCt} key={`${index}`}>
                  <div style={{ ...styles.starBg, width: Math.min(100 * (data.rating - index), 100) + '%', }} />
                  <img src={isDarkTheme ? STAR_ICON_BLACK : STAR_ICON_WHITE} alt={'rating'} style={styles.start} />
                </div>
              ))}
            </div>
            <div style={styles.ratingsCount}>{`${data.ratingsCount} Ratings`}</div>
          </div>
          <div>
            <div style={styles.ageRestrictions}>{`${data.ageRestrictions}+`}</div>
            <div style={styles.ageRestrictionsText}>Age</div>
          </div>
        </div>
      </>
    );
  }

  handleGetClick = () => {
    Api.trackInstall();

    Analytics.trackEvent('link', 'click');

    Attribution.goLink()
      .catch(() => {
        const email = prompt('The application is not available for download at the moment. Please, leave your email. We will let you know when you will be able to download it.');
        if (email) {
          Api.trackEmail(email);
        }
      });
  }

  isInViewport = () => {
    const top = this.viewElement.getBoundingClientRect().top;

    if (top <= 0) {
      this.setState({ isVisibleHeader: true });
    } else {
      this.setState({ isVisibleHeader: false });
    }
  };

  setRef = (element) => this.viewElement = element;
}

const styles = {
  fixedHeader: {
    width: '100vw',
    position: 'fixed',
    top: 0,
    right: 0,
    backgroundColor: THEME.BG,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5vh 5.5vw',
    zIndex: 1,
  },
  fixedGetBtn: {
    width: '22vw',
    height: '7vw',
    textTransform: 'uppercase',
    fontSize: '4vw',
    fontWeight: 'bold',
    backgroundColor: THEME.BTN,
    color: THEME.BTN_TEXT,
    border: 'none',
    borderRadius: '4vw',
  },
  logo: {
    width: '7vw',
    height: '7vw',
    borderRadius: '22%',
  },
  ct: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imgCt: {
    width: '32vw',
    height: '32vw',
  },
  img: {
    width: '32vw',
    height: '32vw',
    borderRadius: '22%',
  },
  infoCt: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '57vw',
    height: '32vw',
    paddingLeft: '3vw',
  },
  info: {
    maxHeight: '71%',
  },
  appName: {
    fontSize: '6vw',
    fontWeight: 600,
    letterSpacing: '-0.12vw',
    lineHeight: '7vw',
    color: THEME.TEXT,
  },
  appSubName: {
    paddingTop: '1vw',
    color: THEME.SUB_TITLE,
    fontSize: '3.85vw',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.8vw 0'
  },
  getBtn: {
    width: '26vw',
    height: '8.5vw',
    marginLeft: '0.7vw',
    textTransform: 'uppercase',
    fontSize: '4.5vw',
    fontWeight: 'bold',
    backgroundColor: THEME.BTN,
    color: THEME.BTN_TEXT,
    border: 'none',
    borderRadius: '5vw',
  },
  shareBtn: {
    width: '5vw',
    height: '6.38vw',
    padding: 0,
    border: 'none',
    background: 'transparent',
  },
  shareBtnIcon: {
    width: '100%',
    height: '100%',
  },
  bottomCt: {
    paddingTop: '8.3vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  startRatingCt: {
    display: 'flex',
    flexDirection: 'row',
  },
  startRating: {
    color: THEME.RATING_MAIN,
    fontSize: '6vw',
    fontWeight: 600,
    lineHeight: '5vw',
    letterSpacing: '-0.3vw',
    marginRight: '1vw',
  },
  startCt: {
    position: 'relative',
    width: '5vw',
    height: '5vw',
    margin: '0 0.2vw'
  },
  starBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: THEME.RATING_MAIN,
    border: `0.2vw solid ${THEME.BG}`,
  },
  start: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  ratingsCount: {
    color: THEME.RATING_SECONDARY,
    marginTop: '1.5vw',
    fontSize: '3vw',
    letterSpacing: '-0.15vw',
    fontWeight: 400,
  },
  ageRestrictions: {
    color: THEME.RATING_MAIN,
    fontSize: '6vw',
    fontWeight: 600,
    lineHeight: '5vw',
    letterSpacing: '-0.3vw',
  },
  ageRestrictionsText: {
    color: THEME.RATING_SECONDARY,
    marginTop: '1.5vw',
    fontSize: '3vw',
    letterSpacing: '-0.15vw',
    textAlign: 'center',
    fontWeight: 400,
  },
};
