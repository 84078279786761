import queryString from 'query-string';
import Attribution from '@wowmaking/web2app';

import Analytics from '../analytics';

let _data = {};

const attribution = {
  init() {
    const { pass, } = queryString.parse(window.location.search);
    Attribution.init({
      onChange: (s, p) => {
        if (s === 'facebook' && p) {
          if (pass !== undefined) {
            Analytics.trackEvent('pass', 'redirect');
            this.goLink()
              .catch(() => { });
          }
        }
      }
    });
  },

  setData(data) {
    _data = data;
  },

  trackImpression() {
    const { mmpLink, } = _data;

    if (mmpLink) {
      const link = queryString.parseUrl(mmpLink);

      if (link.query?.adj_t) {
        Attribution.trackAdjustImpression(link.query.adj_t)
      }
    }
  },

  goLink() {
    return new Promise((resolve, reject) => {
      const { mmpLink, storeLink } = _data;

      if (mmpLink || storeLink) {
        const link = queryString.parseUrl(mmpLink || '');

        window.location.href =
          mmpLink
            ? link.query?.adj_t
              ? Attribution.createAdjustLink(mmpLink, link.query.adj_t)
              : Attribution.createAppsFlyerLink(mmpLink)
            : storeLink;

        resolve();
      }
      else {
        reject();
      }
    });
  },
}

export default attribution;
