import { createRoot } from 'react-dom/client';

import './index.css';
import { App, } from './app';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// prevent zooming
window.document.addEventListener('touchmove', e => {
  if (e.scale !== 1) {
    e.preventDefault();
  }
}, { passive: false });