import React, { PureComponent, } from 'react';

// import template from './assets/images/template3.jpeg';
// import { data } from './data';

import Attribution from './modules/attribution';
import Api from './modules/api';
import Analytics from './modules/analytics';

import { THEME, } from './constants/colors';

import { Header } from './components/header';
import { ImagesSlider } from './components/images-slider';
import { Description, } from './components/description';
import { Reviews, } from './components/reviews';
import { Info, } from './components/info';
import { Loader, } from './components/common/loader';

export class App extends PureComponent {

  state = {
    loading: true,
    data: null
  };

  componentDidMount() {
    document.body.style.backgroundColor = THEME.BG;
    Attribution.init();
    Analytics.init();
    Api.init()
      .then((data) => {
        Attribution.setData(data);
        Attribution.trackImpression();
        document.title = data.appName;
        this.setState({ data, loading: false, });
      });
  }

  render() {
    const { loading, data } = this.state;

    return (
      <div className="App" style={styles.app}>
        {
          loading ?
            <Loader />
            :
            <>
              <Header data={data} />
              <ImagesSlider data={data} />
              <Description data={data} />
              <Reviews data={data} />
              <Info data={data} />
            </>
        }
      </div>
    );
  }

}


const styles = {
  app: {
    padding: '9vw 5.5vw',
    backgroundColor: THEME.BG,
  },
  template: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.5,
    zIndex: -1,
  },
};
