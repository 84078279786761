import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';

import { THEME } from '../constants/colors';

export class ImagesSlider extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  state = {
    fsvVisible: false,
  };

  render() {
    const { data } = this.props;
    const { isPortraitPreviews } = data;

    return (
      <>
        <div style={styles.sliderCt}>
          {data.previews.map((preview, index) => (
            <div
              key={`${index}`}
              style={{
                ...styles.previewCt,
                ...(isPortraitPreviews ? styles.previewCtPortrait : null),
                ...(
                  index === 0 || index === data.previews.length - 1
                    ? (isPortraitPreviews ? styles.previewCtCapPortrait : styles.previewCtCap)
                    : null
                ),
                ...(index === 0 ? styles.previewCtFirst : null),
                ...(index === data.previews.length - 1 ? styles.previewCtLast : null),
              }}
            >
              <div style={styles.previewImgCt} onClick={this.handleFSVShow}>
                <img src={preview.src} alt={'image ' + index} style={styles.previewImg} />
              </div>
            </div>
          ))}
        </div >
        {this.renderFullscreenView()}
      </>
    );
  }

  renderFullscreenView = () => {
    const { data } = this.props;
    const { fsvVisible, } = this.state;

    return (
      <div style={{
        ...styles.fsvCt,
        ...(!fsvVisible ? styles.fsvCtHidden : null),
      }}>

        <div style={styles.fsvHeaderCt}>
          <div style={styles.fsvHeaderBtn} onClick={this.handleFSVHide}>{'Done'}</div>
        </div>

        <div style={styles.fsvSliderCt}>
          {data.previews.map((preview, index) => (
            <div
              key={`${index}`}
              style={{
                ...styles.fsvPreviewCt,
                ...(
                  index === 0 || index === data.previews.length - 1
                    ? styles.fsvPreviewCtCap
                    : null
                ),
                ...(index === 0 ? styles.fsvPreviewCtFirst : null),
                ...(index === data.previews.length - 1 ? styles.fsvPreviewCtLast : null),
              }}
            >
              <div style={styles.fsvPreviewImgCt}>
                <img src={preview.src} alt={'image ' + index} style={styles.fsvPreviewImg} />
              </div>
            </div>
          ))}
        </div>

      </div>
    );
  }

  handleFSVShow = () => {
    this.setState({ fsvVisible: true, });
  }

  handleFSVHide = () => {
    this.setState({ fsvVisible: false, });
  }

}


const styles = {
  sliderCt: {
    margin: '4.5vw -5.5vw',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
  },
  previewCtCap: {
    flex: '0 0 96vw',
  },
  previewCtCapPortrait: {
    flex: '0 0 74vw',
  },
  previewCtFirst: {
    paddingLeft: '5.5vw',
  },
  previewCtLast: {
    paddingRight: '5.5vw',
  },
  previewCt: {
    flex: '0 0 92vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    scrollSnapAlign: 'center',
    padding: '0px 1.5vw',
  },
  previewCtPortrait: {
    flex: '0 0 70vw',
  },
  previewImgCt: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: THEME.BORDER,
    borderRadius: '1vw',
    overflow: 'hidden'
  },
  previewImg: {
    maxWidth: '100%',
    maxHeight: '100%',
  },

  fsvCt: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: THEME.BG,
    zIndex: 999999,
    display: 'flex',
    flexDirection: 'column',
    transition: 'opacity 0.2s, transform 0.3s',
  },
  fsvCtHidden: {
    opacity: 0,
    transform: 'translateY(40vw)',
    pointerEvents: 'none',
  },
  fsvHeaderCt: {
    backgroundColor: THEME.REVIEW_BG,
    borderBottom: `solid 0.5px ${THEME.BORDER}`,
    textAlign: 'end',
  },
  fsvHeaderBtn: {
    display: 'inline-block',
    height: '13vw',
    color: THEME.BTN,
    lineHeight: '13vw',
    fontWeight: 600,
    padding: '0 4vw',
  },
  fsvSliderCt: {
    display: 'flex',
    flexGrow: 1,
    width: '100vw',
    padding: '2vw 0',
    flexDirection: 'row',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
  },
  fsvPreviewCtCap: {
    flex: '0 0 96vw',
  },
  fsvPreviewCtFirst: {
    paddingLeft: '5.5vw',
  },
  fsvPreviewCtLast: {
    paddingRight: '5.5vw',
  },
  fsvPreviewCt: {
    flex: '0 0 92vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    scrollSnapAlign: 'center',
    padding: '0px 1.5vw',
  },
  fsvPreviewImgCt: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: THEME.BORDER,
    borderRadius: '1vw',
    overflow: 'hidden'
  },
  fsvPreviewImg: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
};
