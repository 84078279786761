export const isDarkTheme = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

export const LIGHT_THEME = {
  BG: '#FFFFFF',
  BG_TRANSPARENT: 'rgba(255, 255, 255, 0)',
  TEXT: '#000000',
  SUB_TITLE: '#919194',
  RATING_MAIN: '#8e8f92',
  RATING_SECONDARY: '#c2c2c5',
  BORDER: '#dcdcdd',
  REVIEW_RATING: '#4a4a4d',
  REVIEW_RATING_SECONDARY: '#8a8a8d',
  RATING_BAR: '#7f7f83',
  RATING_BAR_BG: '#e4e4e6',
  REVIEW_BG: '#f2f2f7',
  REVIEW_SECONDARY: '#88888e',
  BTN: '#007afe',
  BTN_TEXT: '#FFFFFF',
};

export const DARK_THEME = {
  BG: '#000000',
  BG_TRANSPARENT: 'rgba(0, 0, 0, 0)',
  TEXT: '#FFFFFF',
  SUB_TITLE: '#8b8b90',
  RATING_MAIN: '#8c8f93',
  RATING_SECONDARY: '#58585b',
  BORDER: '#323235',
  REVIEW_RATING: '#ebebf5',
  REVIEW_RATING_SECONDARY: '#8d8d92',
  RATING_BAR: '#9e9ea5',
  RATING_BAR_BG: '#2b2a2d',
  REVIEW_BG: '#1b1b1d',
  REVIEW_SECONDARY: '#919197',
  BTN: '#0284ff',
  BTN_TEXT: '#FFFFFF',
};

export const THEME = isDarkTheme
  ? DARK_THEME
  : LIGHT_THEME;

