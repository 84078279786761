import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';

import { THEME } from '../constants/colors';

export class Info extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { data } = props;
    this.state = {
      info: [
        { title: 'Provider', value: data.publisherName, },
        { title: 'Version', value: data.version, },
        { title: 'Size', value: data.size, },
        { title: 'Category', value: data.category, },
        { title: 'Age rating', value: `${data.ageRestrictions}+`, },
        { title: 'Copyright', value: data.copyright, },
      ],
    };
  }

  render() {
    const { info } = this.state;

    return (
      <div style={styles.ct}>
        <div style={styles.title}>{'Information'}</div>
        <div style={styles.infoListCt}>
          {info.map(({ title, value }) => (
            <div style={styles.infoListItem} key={title}>
              <div style={styles.infoListItemTitle}>{title}</div>
              <div style={styles.infoListItemValue}>{value}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

}

const styles = {
  ct: {
    marginTop: '9vw',
    paddingTop: '4.5vw',
    borderTop: `solid 0.5px ${THEME.BORDER}`,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '5.8vw',
    lineHeight: '3vw',
    color: THEME.TEXT,
  },
  infoListCt: {
    marginTop: '7vw',
  },
  infoListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '4vw 0',
    // marginTop: '1.5vw',
    fontSize: '4.2vw',
    letterSpacing: '-0.1vw',
    borderBottom: `solid 0.5px ${THEME.BORDER}`
  },
  infoListItemTitle: {
    color: THEME.SUB_TITLE,
  },
  infoListItemValue: {
    color: THEME.TEXT,
  },
};
