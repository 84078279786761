import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { THEME } from '../../constants/colors';

export class CollapsibleText extends PureComponent {

  static propTypes = {
    text: PropTypes.string.isRequired,
  };

  state = {
    collapsed: true,
  };

  render() {
    const { text } = this.props;
    const { collapsed } = this.state;
    return (
      <div style={{ ...styles.textCt, ...(!collapsed ? styles.textCtExpanded : null) }}>
        {text}
        {collapsed && <div style={styles.moreBtn} onClick={this.handleMoreClick}>{'more'}</div>}
      </div>
    );
  }

  handleMoreClick = () => {
    this.setState({ collapsed: false });
  }

}

const styles = {
  textCt: {
    position: 'relative',
    whiteSpace: 'pre-wrap',
    lineHeight: '5.2vw',
    color: THEME.TEXT,
    overflow: 'hidden',
    maxHeight: '15vw',
    fontSize: '4.05vw',
  },
  textCtExpanded: {
    maxHeight: 'fit-content',
  },
  moreBtn: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    lineHeight: '5vw',
    color: THEME.BTN,
    backgroung: THEME.BG,
    background: `linear-gradient(90deg, ${THEME.BG_TRANSPARENT} 0%, ${THEME.BG} 30%, ${THEME.BG} 100%)`,
    paddingLeft: '5vw',
    fontSize: '4.05vw',
  },
};