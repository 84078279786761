import DeviceProps from '@magnus/react-native-device-props';
import EvTruck from '@magnus/react-native-evtruck';
import Session from '@wowmaking/react-native-analytics/core/helpers/session';
import queryString from 'query-string';

import packageInfo from '../../../package.json';

const Analytics = {
  init() {
    DeviceProps.setAppVersion(packageInfo.version);

    EvTruck.init({
      token: process.env.REACT_APP_MAGNUS_TOKEN,
    });

    return Session.init(this).then(() => {
      const p = queryString.parse(window.location.search);

      this.setUserProperty('domain', window.location.hostname);

      if (p.utm_source) {
        this.setUserProperty('utm_source', p.utm_source);
      }

      if (p.campaign_id) {
        this.setUserProperty('campaign_id', p.campaign_id);
      }
    });
  },

  setUserProperty(name, value) {
    EvTruck.setUserProperty(name, value);
  },

  trackEvent(category, action, params) {
    const event = `${category}_${action}`;
    EvTruck.trackEvent(event, params);
    window.dataLayer?.push({ event, ...params });
  },
};

export default Analytics;
